import React from "react";
import "./Hero.scss";

export default function Hero(props) {
  return (
    <div className="Hero">
      <div className="herobox">
        <div className="title">
          <span className="block"></span>
          <h1>Serhii Prykhozhyi</h1>
        </div>
        <div className="role">
          <div className="block"></div>
          <p>Full Stack JavaScript Developer</p>
        </div>
      </div>
    </div>
  );
}
