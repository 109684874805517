import React, { useEffect } from "react";
import { useTrackVisibility } from "react-intersection-observer-hook";
import { motion, useAnimation } from "framer-motion";
import { makeVisible, moveFromLeft, moveFromRight } from "../animation-helpers";
import "./Stack.scss";

import { DiReact, DiNodejsSmall, DiFirebase, DiHtml5, DiCss3, DiGit } from "react-icons/di";

export default function Stack() {
  const controls = useAnimation();
  const [ref, { isVisible }] = useTrackVisibility();

  const headers = moveFromLeft;

  const paragraphs = makeVisible;

  const images = moveFromRight;

  useEffect(() => {
    if (isVisible) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isVisible]);

  return (
    <div className="Stack">
      <motion.h3
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={headers}
      >
        Technologies I use
      </motion.h3>
      <div className="Stack-desc">
        <div className="wr_left">
          <motion.p
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={paragraphs}
          >
            My toolkit as a Full Stack Web Developer is broad and continually growing. I specialize in JavaScript, but I'm also well-versed in HTML and CSS, including their advanced aspects. Here are some of the technologies and tools I work with:
          </motion.p>
          <motion.p
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={paragraphs}
          >
            <strong>Frontend:</strong> ReactJS, NextJS, SCSS, TailwindCSS, Styled Components, CSS-in-JS, CSS Modules, Bootstrap, Material-UI, Semantic UI
          </motion.p>
          <motion.p
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={paragraphs}
          >
            <strong>Backend:</strong> NodeJS, ExpressJS
          </motion.p>
          <motion.p
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={paragraphs}
          >
            <strong>Database & Cloud:</strong> Firebase (Database, Storage, Authentication, Serverless Cloud Functions)
          </motion.p>
          <motion.p
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={paragraphs}
          >
            <strong>Version Control, CI/CD and Tools:</strong> Git, Github (including Github Actions), GitLab (with CI/CD), Selenium Webdriver
          </motion.p>
          <motion.p
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={paragraphs}
          >
            <strong>Design:</strong> Adobe Photoshop, Figma
          </motion.p>
          <motion.p
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={paragraphs}
          >
            Currently, I'm exploring Python and further expanding my skill set. I believe that learning never stops in tech!
          </motion.p>
        </div>
        <motion.div
          className="wr_right"
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={images}
        >
          <div className="tech">
            <DiReact color="#545E75" />
          </div>
          <div className="tech">
            <DiNodejsSmall color="#545E75" />
          </div>
          <div className="tech">
            <DiHtml5 color="#545E75" />
          </div>
          <div className="tech">
            <DiCss3 color="#545E75" />
          </div>
          <div className="tech">
            <DiFirebase color="#545E75" />
          </div>
          <div className="tech">
            <DiGit color="#545E75" />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
