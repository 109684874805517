// import React, { useEffect } from "react";
// import { useTrackVisibility } from "react-intersection-observer-hook";
import { motion, 
  // useAnimation 
} from "framer-motion";
// import { makeVisible, moveFromLeft } from "../animation-helpers";
import "./Projects.scss";

export default function Projects() {
  // const controls = useAnimation();
  // const [ref, { isVisible }] = useTrackVisibility();

  // const paragraphs = makeVisible;
  // const headers = moveFromLeft;

  // useEffect(() => {
  //   if (isVisible) {
  //     controls.start("visible");
  //   } else {
  //     controls.start("hidden");
  //   }
  // }, [controls, isVisible]);

  return (
    <div>

      <div className="slide">
        <div className="Project">
          <h3>Project: VideoDub</h3>
          <div className="Project-desc">
            <div>
              <img src="img/videodub-image.png" alt="VideoDub - AI powered Voice Over" />
            </div>
            <div className="Project-text">
              <p>
              Videodub.io is an AI-powered platform that simplifies the process of adding professional-quality voice-overs to videos. It transcribes the speech, translates it (if required), and adds a natural-sounding voice-over in the language of your choice. It is a necessary tool for content creators seeking to extend their reach globally with minimal effort and no manual work.
              </p>
              <div>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #222",
                  }}
                  href="https://videodub.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  URL
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      
      <div className="slide">
        <div className="Project">
          <h3
          >
            Project: JobNext
          </h3>
          <div
            className="Project-desc"
          >
            <div>
              <img src="img/jobnext.jpg" alt="Jobhunter - Landing Page" />
            </div>
            <div className="Project-text">
              <p>
                JobNext is a landing page that was transformed from a Figma UI
                design to a responsive web page. This website was developed
                using ReactJS and Styled Components.
              </p>
              <div>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #999",
                  }}
                  href="https://compassionate-yonath-1fb380.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  URL
                </motion.a>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #999",
                  }}
                  href="https://github.com/sprihozhiy/jobnext-landing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slide">
        <div className="Project">
          <h3>Project: TokenSpector - Website and Blog</h3>
          <div className="Project-desc">
            <div>
              <img src="img/tokenspector-website.png" alt="TokenSpector - Website and Blog" />
            </div>
            <div className="Project-text">
              <p>
              TokenSpector is a website and blog for the Crypto Analysis tool. Technologies used: NextJS and TailwindCSS. Design is custom and created using Figma.
              </p>
              <div>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #222",
                  }}
                  href="https://tokenspector.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  URL
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slide">
        <div className="Project">
          <h3>Project: TokenSpector - Scanner and API Service</h3>
          <div className="Project-desc">
            <div>
              <img src="img/tokenspector-scanner-api.png" alt="TokenSpector - Scanner and API Service" />
            </div>
            <div className="Project-text">
              <p>
              TokenSpector is a Smart Contract Scanner tool and an API service for analysis. Technologies used: NextJS and TailwindCSS for the Scanner and ExpressJS, Heroku, Web3 for the API service. Design is custom and created using Figma.
              </p>
              <div>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #222",
                  }}
                  href="https://app.tokenspector.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  URL
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slide">
        <div className="Project">
          <h3>Project: TokenSpector - Telegram Signals</h3>
          <div className="Project-desc">
            <div>
              <img src="img/tokenspector-telegram.png" alt="TokenSpector - Telegram Channel" />
            </div>
            <div className="Project-text">
              <p>
              TokenSpector Signals is a Telegram channel that informs subscribers about new listings on CoinMarketCap in real-time. Technologies used: ExpressJS, Telegraf, Heroku, Firestore, and Web3 and external API services.
              </p>
              <div>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #222",
                  }}
                  href="https://t.me/tokenspectorlistings"
                  target="_blank"
                  rel="noreferrer"
                >
                  Telegram
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slide">
        <div className="Project">
          <h3>Project: React + TailwindCSS + Firebase Boilerplate</h3>
          <div className="Project-desc">
            <div>
              <img
                src="img/boilerplate.png"
                alt="Rapid Development Boilerplate"
              />
            </div>
            <div className="Project-text">
              <p>
                A boilerplate for rapid ReactJS development using Firebase with
                Firestore as a BaaS and a utility-first CSS framework
                TailwindCSS.
              </p>
              <div>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #222",
                  }}
                  href="https://github.com/sprihozhiy/react-firebase-tailwindcss-boilerplate"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="Project">
          <h3>Project: Diabetter</h3>
          <div className="Project-desc">
            <div>
              <img
                src="img/diabetter.png"
                alt="Diabetter - Diabetes Management"
              />
            </div>
            <div className="Project-text">
              <p>
                Diabetter is a Diabetes Management Application that will help
                people with controling their sugar level in the blood. This web
                application is in progress. Desing - on Figma, tech stack -
                React, Sass, Firebase, Chart.js and other tools.
              </p>
              <p>Login: demo-user@mail.com</p>
              <p>Password: 123456</p>
              <div>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #222",
                  }}
                  href="https://dialogs-v2.web.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Demo
                </motion.a>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #222",
                  }}
                  href="https://www.figma.com/file/JPlaeMPDK6t6TwBktMVxEE/Dialogs?node-id=42%3A2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Design
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="Project">
          <h3>Project: The Shoppies</h3>
          <div className="Project-desc">
            <div>
              <img src="img/the-shoppies.png" alt="The Shoppies" />
            </div>
            <div className="Project-text">
              <p>
                The Shoppies is a movie nomination tools that helps users to
                find the movie they like and add it to a nominated films. This
                webs application was developed using ReactJS and OMDb API.
              </p>
              <div>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #222",
                  }}
                  href="https://the-shoppies-fcdbb.web.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  URL
                </motion.a>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "2px 2px 2px #222",
                  }}
                  href="https://github.com/sprihozhiy/the-shoppies"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
