import React, { useEffect } from "react";
import { useTrackVisibility } from "react-intersection-observer-hook";
import { motion, useAnimation } from "framer-motion";
import { makeVisible, moveFromLeft } from "../animation-helpers";
import "./About.scss";

export default function About() {
  const controls = useAnimation();
  const [ref, { isVisible }] = useTrackVisibility();

  const headers = moveFromLeft;

  const paragraphs = makeVisible;

  useEffect(() => {
    if (isVisible) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isVisible]);

  return (
    <div className="About">
      <motion.h3
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={headers}
      >
        About Me
      </motion.h3>
      <div className="About-desc">
        <div>
          <img src="./img/serhii.jpg" alt="Serhii Prykhozhyi" />
        </div>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={paragraphs}
        >
          <p>
            Hi, my name is Serhii! I am a Full Stack JavaScript Developer with a
            focus on Frontend from Calgary, Alberta.
          </p>
          <p>
            My journey into web development has started several years ago when I
            tried to fulfil my childhood dream. There were numerous online
            courses and books about HTML, CSS and JavaScript that I went
            through. Codecademy, Udemy, Udacity, TeamTreeHouse - you name it!
          </p>
          <p>
            Also, I completed the Frontend Development Bootcamp at Computer Academy
            "STEP" in Ukraine (2016) and finished the React JS course at ComIT in Calgary (2020),
            worked on polishing my dev skills on different freelance and personal projects. All those efforts and skills transformed into a professional role as a web developer.
          </p>
          <p>
            Currently, I'm a web developer at <a href="https://blockstream.com">Blockstream Inc</a>. I develop, support, and maintain all the company's main websites and a few web applications. I enjoy using my skills to contribute to Blockstream's work every day.
          </p>
          <div className="About-desc_links">
            <motion.a
              whileHover={{
                scale: 1.1,
                boxShadow: "2px 2px 2px #222",
              }}
              href="/Resume_JavaScript_Developer_Serhii.pdf"
              target="_blank"
              rel="noreferrer"
              className="link-btn"
            >
              Resume
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                boxShadow: "2px 2px 2px #222",
              }}
              href="https://github.com/sprihozhiy"
              target="_blank"
              rel="noreferrer"
              className="link-btn"
            >
              GitHub
            </motion.a>
          </div>
        </motion.div>
      </div>
    </div>
  );
}