import "./App.scss";
import ReactFullpage from "@fullpage/react-fullpage";
import { FULLPAGE_JS_API } from "./API";

import About from "./components/About";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import Stack from "./components/Stack";

function App() {
  return (
    <ReactFullpage
      licenseKey={FULLPAGE_JS_API}
      scrollingSpeed={1000}
      navigation={true}
      navigationPosition={"right"}
      render={({ fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section section1">
              <Hero fullpageApi={fullpageApi} />
            </div>
            <div className="section section2">
              <About />
            </div>
            <div className="section section3">
              <Stack />
            </div>
            <div className="section section4">
              <Projects />
            </div>
            <div className="section section5">
              <Contact />
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

export default App;
