export const moveFromLeft = {
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.5 },
  },
  hidden: {
    x: -300,
    opacity: 0,
  },
};

export const moveFromRight = {
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
  hidden: {
    x: 300,
    opacity: 0,
  },
};

export const moveFromDown = {
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
  hidden: {
    y: 300,
    opacity: 0,
  },
};

export const makeVisible = {
  visible: {
    opacity: 1,
    transition: { duration: 0.7 },
  },
  hidden: {
    opacity: 0,
  },
};
