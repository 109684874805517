import React, { useEffect } from "react";
import { useTrackVisibility } from "react-intersection-observer-hook";
import { motion, useAnimation } from "framer-motion";
import { makeVisible, moveFromLeft } from "../animation-helpers";
import "./Contact.scss";

export default function Contact() {
  const controls = useAnimation();
  const [ref, { isVisible }] = useTrackVisibility();

  const headers = moveFromLeft;

  const paragraphs = makeVisible;

  useEffect(() => {
    if (isVisible) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isVisible]);

  return (
    <div className="Contact">
      <motion.h3
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={headers}
      >
        Contacts
      </motion.h3>
      <motion.div
        className="Contact-desc"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={paragraphs}
      >
        <p>Feel free to reach me out!</p>
        <a
          href="https://github.com/sprihozhiy"
          target="_blank"
          rel="noreferrer"
        >
          <motion.img
            src="img/github.svg"
            alt="github"
            whileHover={{
              scale: 1.1,
            }}
          />
        </a>
        <a
          href="https://www.linkedin.com/in/serhii-prykhozhyi/"
          target="_blank"
          rel="noreferrer"
        >
          <motion.img
            src="img/linkedin.svg"
            alt="linkedin"
            whileHover={{
              scale: 1.1,
            }}
          />
        </a>
        <a
          href="https://m.me/sergey.prikhozhiy"
          target="_blank"
          rel="noreferrer"
        >
          <motion.img
            src="img/facebook-messenger.svg"
            alt="facebook messenger"
            whileHover={{
              scale: 1.1,
            }}
          />
        </a>
      </motion.div>
    </div>
  );
}
